// native
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// services
import { TestBundlesService } from './test-bundles.service';
import { TestsService } from './tests.service';

// models
import { ControlType, Device, PendingTestBundleItem, Test, TestDescription, TestGroup, TestLanguage, TestListItem, TestProtocol, TestStrategy } from '../../models';

// constants
import {
  DEVICE_TYPE, EYE, TEST_BUNDLE_TYPES, STRATEGY, GROUP, PEDIATRIC_PROTOCOLS, CONTROL_TYPES,
  TEST_LANGUAGES, SUPRA_T_INTENSITIES, GOLDMAN_SIZES, PROTOCOL, DEFAULT_OCCLUSION_TIME
} from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class TestFormService {
  constructor(
    private testBundlesService: TestBundlesService,
    private testsService: TestsService
  ) { }

  areCustomBundlesDisplayed: boolean = false;
  customBundleGroupValue = GROUP.CUSTOM_BUNDLE;

  isGroupVisualHintDisplayed: boolean = false;
  isStrategyVisualHintDisplayed: boolean = false;
  isLanguageVisualHintDisplayed: boolean = false;

  isTestWithEyeSelection(group: TestGroup, strategy: TestStrategy, device?: Device, monocular?: boolean): boolean {
    if ([GROUP.COLOR_VISION, GROUP.EOMS, GROUP.SENSORIMOTOR_SHAPES, GROUP.SENSORIMOTOR_CARDINAL].includes(group?.value))
      return true;

    if ([
      STRATEGY.AVA_FAST, STRATEGY.AVA_STANDARD, STRATEGY.CONFRONTATION, STRATEGY.FULL_THRESHOLD, STRATEGY.PTOSIS,
      STRATEGY.SUPRA_FAST, STRATEGY.SUPRA_THRESHOLD, STRATEGY.KINETIC, STRATEGY.FOVEAL_SENSITIVITY, STRATEGY.BLEPHARO,
      STRATEGY.EOMS_SENSORIMOTOR].includes(strategy?.value))
      return true;

    if ((STRATEGY.ESTERMAN === strategy?.value) && (device?.capabilities?.esterman_algorithm_version >= 4) && !!monocular)
      return true;

    if ((STRATEGY.CONTRAST_STANDARD === strategy?.value) && !!monocular)
      return true;

    return false;
  }

  isTestWithCorrection(group: TestGroup, strategy: TestStrategy, test: Test = null): boolean {
    if (group?.value === GROUP.VISUAL_ACUITY && this.isTestPartOfCSuite(test))
      return false;
    if ([GROUP.VISUAL_ACUITY].includes(group?.value))
      return true;
    if ([STRATEGY.COVER_SENSORIMOTOR, STRATEGY.CONTRAST_SINGLE_OPTOTYPE].includes(strategy?.value))
      return true;
    return false;
  }

  isTestWithCorrectionType(group: TestGroup, test: Test = null): boolean {
    if (group?.value === GROUP.CSUITE)
      return true;

    return (group?.value === GROUP.VISUAL_ACUITY && this.isTestPartOfCSuite(test));
  }

  isTestPartOfCSuite(test: Test | TestListItem | TestDescription): boolean {
    const bundleType = (<Test | TestListItem>test)?.test_bundle?.system_test_bundle_type || (<TestDescription>test)?.original_system_test_bundle_type;
    if (!bundleType)
      return false;

    return (bundleType === this.testBundlesService.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id);
  }

  isGoldmanSizeDisplayed(strategy: TestStrategy, device?: Device): boolean {
    if (device?.device_type === DEVICE_TYPE.OFFICE_CHOICE)
      return false;
    return strategy?.goldman_size?.length > 1;
  }

  isGoldmanSizeConfigurable(strategy: TestStrategy, device?: Device): boolean {
    if (device?.device_type === DEVICE_TYPE.OFFICE_CHOICE)
      return false;
    return strategy?.goldman_size && strategy?.goldman_size?.length > 0;
  }

  getDefaultGoldmanSize(strategy?: TestStrategy, protocol?: TestProtocol): number {
    if (strategy?.value === STRATEGY.CONFRONTATION)
      return GOLDMAN_SIZES.FIVE.value;

    if (protocol?.value === PROTOCOL.SUPRA_THRESHOLD_QUADRANT)
      return GOLDMAN_SIZES.FIVE.value;

    return GOLDMAN_SIZES.THREE.value;
  }

  getGoldmanSizeLabel(goldmanSize: number): string {
    const sizes = Object.values(GOLDMAN_SIZES);
    return sizes.find(size => size.value === goldmanSize)?.label || GOLDMAN_SIZES.THREE.label;
  }

  isTestWithStimulusType(strategy: TestStrategy): boolean {
    return strategy?.stimulus_type;
  }

  isTestWithApdStimulationTime(strategy: TestStrategy): boolean {
    return strategy?.apd_stimulation_time;
  }

  isTestWithIntensity(strategy: TestStrategy, protocol: TestProtocol, device: Device, isCustomBundle?: boolean): boolean {
    if (protocol?.value === PROTOCOL.SUPRA_THRESHOLD_QUADRANT)
      return false;
    if ([STRATEGY.SUPRA_THRESHOLD, STRATEGY.SUPRA_FAST].includes(strategy?.value))
      return true;
    if ((strategy?.value === STRATEGY.ESTERMAN) && isCustomBundle)
      return true;
    if ((strategy?.value === STRATEGY.ESTERMAN) && (device?.capabilities?.esterman_algorithm_version > 1))
      return true;
    return false;
  }

  isTestWithSupraIntensity(strategy: TestStrategy): boolean {
    return [STRATEGY.SUPRA_THRESHOLD, STRATEGY.SUPRA_FAST].includes(strategy?.value);
  }

  isTestWithPositions(strategy: TestStrategy): boolean {
    return [STRATEGY.COVER_SENSORIMOTOR].includes(strategy?.value);
  }

  isTestWithDefaultDigitalOcclusion(strategy: TestStrategy): boolean {
    return [STRATEGY.COVER_SENSORIMOTOR].includes(strategy?.value);
  }

  isTestWithConvergence(strategy: Partial<TestStrategy>): boolean {
    return [STRATEGY.FULL_THRESHOLD, STRATEGY.AVA_FAST, STRATEGY.AVA_STANDARD, STRATEGY.CONFRONTATION,
    STRATEGY.PTOSIS, STRATEGY.SUPRA_FAST, STRATEGY.SUPRA_THRESHOLD, STRATEGY.FOVEAL_SENSITIVITY, STRATEGY.BLEPHARO].includes(strategy?.value);
  }

  isTestWithNonConvergenceMonocular(group: Partial<TestGroup>, strategy: Partial<TestStrategy>, eye?: string, device?: Device, isCustomBundle?: boolean): boolean {
    if (group?.value === GROUP.COLOR_VISION && !(<string[]>[EYE.OD, EYE.OS]).includes(eye))
      return true;

    if ((strategy?.value === STRATEGY.ESTERMAN) && ((device?.capabilities?.esterman_algorithm_version > 1) || isCustomBundle))
      return true;

    if (strategy?.value === STRATEGY.CONTRAST_STANDARD)
      return true;

    return false;
  }

  isMonocularConfrontationCheckboxShown(group: Partial<TestGroup>, device: Device): boolean {
    if (group?.value !== GROUP.CSUITE)
      return false;
    if (device?.capabilities?.bundle_monocular_algorithm_version > 1)
      return true;
  }

  isMonocularColorCheckboxShown(group: Partial<TestGroup>): boolean {
    return group?.value === GROUP.CSUITE;
  }

  isBothEyesSelected(form: UntypedFormGroup): boolean {
    if (!form)
      return true;
    if (form.controls.eye.value === EYE.BOTH_CLIENT)
      return true;
    return false;
  }

  isSingleEyeSelected(form: UntypedFormGroup): boolean {
    if (!form)
      return false;
    if (form.controls.eye.value === EYE.BOTH_CLIENT)
      return false;
    return true;
  }

  isEyeMovableTest(group: TestGroup): boolean {
    return [GROUP.SENSORIMOTOR_SHAPES, GROUP.SENSORIMOTOR_CARDINAL].includes(group?.value);
  }

  isTestWithControlType(group: Partial<TestGroup>, strategy: Partial<TestStrategy>, protocol: Partial<TestProtocol>, languageValue?: string): boolean {
    const inputLanguages = strategy?.voice_input_languages || group?.voice_input_languages || [];
    if (languageValue && !inputLanguages.includes(languageValue))
      return false;

    if (protocol)
      return protocol.control_type;
    if (strategy)
      return strategy.control_type;
    return group?.control_type;
  }

  isTestWithFoveal(strategy: TestStrategy, device?: Device): boolean {
    if (device?.device_type === DEVICE_TYPE.OFFICE_CHOICE)
      return false;
    return [STRATEGY.FULL_THRESHOLD, STRATEGY.AVA_FAST, STRATEGY.AVA_STANDARD].includes(strategy?.value);
  }

  isTestWithDisablePause(device: Device, strategy: TestStrategy): boolean {
    if (!device)
      return false;
    if ([DEVICE_TYPE.EYE_TRACKING, DEVICE_TYPE.HOME_EYE_TRACKING].includes(device?.device_type))
      return false;

    return [
      STRATEGY.FULL_THRESHOLD,
      STRATEGY.AVA_FAST,
      STRATEGY.AVA_STANDARD,
      STRATEGY.SUPRA_THRESHOLD,
      STRATEGY.SUPRA_FAST,
      STRATEGY.ESTERMAN,
      STRATEGY.PTOSIS,
      STRATEGY.CONFRONTATION,
      STRATEGY.KINETIC
    ].includes(strategy?.value);
  }

  isTestWithTutorial(group: TestGroup, strategy: TestStrategy, protocol: TestProtocol): boolean {
    if (group?.value === GROUP.CUSTOM_BUNDLE)
      return true;
    if (protocol)
      return protocol.skip_tutorial;
    if (strategy)
      return strategy.skip_tutorial;
    return group?.skip_tutorial;
  }

  isTestWithShortTutorial(group: TestGroup, strategy: TestStrategy, protocol: TestProtocol, languageValue: string): boolean {
    if (!this.isMainLanguage(languageValue))
      return false;

    if ([GROUP.CSUITE, GROUP.CUSTOM_BUNDLE].includes(group?.value))
      return true;

    if (group?.value === GROUP.PERIMETRY
      && ![...PEDIATRIC_PROTOCOLS, PROTOCOL.SUPRA_THRESHOLD_QUADRANT].includes(protocol?.value)
      && ![STRATEGY.KINETIC].includes(strategy?.value))
      return true;

    return false;
  }

  isTestWithSubtitles(device: Device, languageValue: string, group: TestGroup, strategy?: TestStrategy): boolean {
    if (device && languageValue && !device.capabilities?.subtitles_languages?.includes(languageValue))
      return false;
    if (group?.value === GROUP.CUSTOM_BUNDLE)
      return true;
    if (strategy)
      return strategy.subtitles;
    return group?.subtitles;
  }

  isTestWithIncreasedSubtitles(device: Device, languageValue: string, selectedSubtitles: boolean, group: TestGroup, strategy?: TestStrategy): boolean {
    if (!this.isTestWithSubtitles(device, languageValue, group, strategy))
      return false;
    return device?.capabilities?.increased_size_subtitles_languages?.includes(languageValue) && !!selectedSubtitles;
  }

  isTestWithSkipCalibration(group: TestGroup, protocol: TestProtocol, controlType: ControlType): boolean {
    if ((controlType?.name === CONTROL_TYPES.VOICE) && (group?.value !== GROUP.CSUITE))
      return false;
    if (protocol)
      return protocol.skip_calibration;
    return group?.skip_calibration;
  }

  isTestWithSkipEyeTrackingCalibration(group: TestGroup, strategy: TestStrategy): boolean {
    if (strategy)
      return strategy.skip_eye_tracking_calibration;
    return group?.skip_eye_tracking_calibration;
  }

  isTestWithOcclusionTime(group: TestGroup, strategy: TestStrategy): boolean {
    if (group?.value === GROUP.COVER)
      return true;
    if (strategy?.value === STRATEGY.COVER_SENSORIMOTOR)
      return true;
    return false;
  }

  getDisplayedLanguages(device?: Device, group?: TestGroup, strategy?: TestStrategy, controlType?: ControlType): TestLanguage[] {
    if (!device)
      return this.sortLanguages(this.testsService.allTestLanguages.map(value => TEST_LANGUAGES[value]));

    if (controlType?.name === CONTROL_TYPES.VOICE) {
      if (strategy?.voice_input_languages?.length)
        return strategy.voice_input_languages.map(value => TEST_LANGUAGES[value]);

      if (group?.voice_input_languages?.length)
        return group.voice_input_languages.map(value => TEST_LANGUAGES[value]);
    }

    return this.sortLanguages(device.capabilities?.output_languages?.map(value => TEST_LANGUAGES[value]));
  }

  private sortLanguages(languages: TestLanguage[]): TestLanguage[] {
    if (!languages?.length)
      return languages;
    const firstOrdered = languages.filter(language => ['english', 'spanish', 'fr-CA'].includes(language?.value));
    const lastOrdered = languages.filter(language => !['english', 'spanish', 'fr-CA'].includes(language?.value));
    lastOrdered.sort((a, b) => a.name.localeCompare(b.name));
    return firstOrdered.concat(lastOrdered);
  }

  isMainLanguage(value: string): boolean {
    return ['english', 'spanish'].includes(value);
  }

  private showGroupVisualHint() {
    this.isGroupVisualHintDisplayed = true;
  }

  private showStrategyVisualHint() {
    this.isStrategyVisualHintDisplayed = true;
  }

  hideGroupVisualHint() {
    this.isGroupVisualHintDisplayed = false;
  }

  hideStrategyVisualHint() {
    this.isStrategyVisualHintDisplayed = false;
  }

  showLanguageVisualHint() {
    this.isLanguageVisualHintDisplayed = true;
  }

  hideLanguageVisualHint() {
    this.isLanguageVisualHintDisplayed = false;
  }

  setCustomBundleDisplay(form: UntypedFormGroup, isInitialSet: boolean = false) {
    if (isInitialSet && form.controls['group'].value === this.customBundleGroupValue)
      this.areCustomBundlesDisplayed = true;
    else if (form.controls['group'].value === this.customBundleGroupValue) {
      this.areCustomBundlesDisplayed = true;
      form.controls['custom_test_bundle_type']?.enable();
      form.controls['custom_test_bundle_type']?.setValue(null);
    } else {
      this.areCustomBundlesDisplayed = false;
      form.controls['custom_test_bundle_type']?.disable();
      form.controls['custom_test_bundle_type']?.setValue(null);
    }
  }

  handleCustomBundleDisplayOnDeviceChange(form: UntypedFormGroup, displayedCustomBundles: TestGroup[]) {
    const customBundleControl = form.controls['custom_test_bundle_type'];
    if (!displayedCustomBundles?.length) {
      form.controls['group'].setValue(null);
      customBundleControl?.setValue(null);
      this.areCustomBundlesDisplayed = false;
      return;
    }
    if (!displayedCustomBundles.find(bundle => bundle.custom_test_bundle_info?.custom_test_bundle_id === customBundleControl.value))
      customBundleControl?.setValue(null);
  }

  resetSelectionAndShowHint(form: UntypedFormGroup, selectedTestGroup: TestGroup, selectedTestStrategy: TestStrategy, hadGroupSelected: boolean, hadStrategySelected: boolean) {
    if (!selectedTestGroup?.value && hadGroupSelected) {
      form.controls['group'].setValue(null);
      form.controls['strategy'].setValue(null);
      form.controls['protocol'].setValue(null);
      this.showGroupVisualHint();
      return;
    }

    if (!selectedTestStrategy?.value && hadStrategySelected) {
      form.controls['strategy'].setValue(null);
      form.controls['protocol'].setValue(null);
      this.showStrategyVisualHint();
    }
  }

  shouldShowConfirmModal(form: UntypedFormGroup, controlTypes: ControlType[], group: TestGroup,
    strategy: TestStrategy, protocol: TestProtocol): boolean {
    const hasVoiceControlSelected = form.controls['control_type']?.value === controlTypes.find(type => type.name === CONTROL_TYPES.VOICE)?.id;
    const isColorTest = group?.value === GROUP.COLOR_VISION;
    return hasVoiceControlSelected && !isColorTest && this.isTestWithControlType(group, strategy, protocol);
  }

  calculateMonocularPayload(selectedConvergenceSkip: boolean, selectedMonocular: boolean, group: Partial<TestGroup>, strategy: Partial<TestStrategy>, eye: string, device: Device): boolean {
    if (this.isTestWithConvergence(strategy)) {
      return selectedConvergenceSkip ? selectedMonocular : null;
    }
    if (this.isTestWithNonConvergenceMonocular(group, strategy, eye, device)) {
      return selectedMonocular || false;
    }
    return null;
  }

  calculateBundleMonocularPayload(test: PendingTestBundleItem, convergenceMonocularValue: boolean, device: Device): boolean {
    if (this.isTestWithConvergence(test.test_group?.strategy))
      return convergenceMonocularValue;

    if (this.isTestWithNonConvergenceMonocular(test.test_group?.group, test.test_group?.strategy, test.eye, device))
      return test.monocular || false;

    return null;
  }

  calculateIntensityPayload(strategy: TestStrategy, protocol: TestProtocol, device: Device, selectedIntensity: string, isCustomBundle?: boolean): string {
    if (this.isTestWithIntensity(strategy, protocol, device, isCustomBundle))
      return selectedIntensity;

    if ([STRATEGY.CONFRONTATION, STRATEGY.BLEPHARO].includes(strategy?.value))
      return SUPRA_T_INTENSITIES.HIGH.value;

    if (protocol?.value === PROTOCOL.SUPRA_THRESHOLD_QUADRANT)
      return SUPRA_T_INTENSITIES.MAX.value;
    return null;
  }

  calculateGoldmanSizePayload(strategy: TestStrategy, selectedGoldmanSize: number): number {
    if (strategy?.value === STRATEGY.BLEPHARO)
      return GOLDMAN_SIZES.THREE.value;

    if (this.isGoldmanSizeConfigurable(strategy))
      return selectedGoldmanSize;

    return null;
  }

  isTestWithLanguage(device: Device): boolean {
    if (!device)
      return true;
    return device.device_type !== DEVICE_TYPE.CAMERA;
  }

  calculateOcclusionTimePayload(group: TestGroup, strategy: TestStrategy, selectedOcclusionTime: number): number {
    if (!this.isTestWithOcclusionTime(group, strategy))
      return null;
    return selectedOcclusionTime || DEFAULT_OCCLUSION_TIME;
  }
}