// native
import { Component, Input, OnInit } from '@angular/core';

// service
import { AuthService } from 'app/core/services/auth.service';
import { TechallSessionService } from 'app/core/services/techall-session.service';

// models
import { OfficeGroupItem, UserAuthResponse } from '../../../models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  officeGroups: OfficeGroupItem[];
  selectedOfficeGroupId: number;

  isLoading: boolean = false;

  @Input() navExpanded;
  @Input() transparentMode;

  constructor(
    public authService: AuthService,
    public techallService: TechallSessionService
  ) { }

  ngOnInit(): void {
    this.officeGroups = this.authService.getOfficeGroups();
    this.selectedOfficeGroupId = this.authService.storedOfficeGroupId;
  }

  submit() {
    this.isLoading = true;
    this.authService.switchOfficeGroup(this.selectedOfficeGroupId).subscribe(() => {
      this.isLoading = true;
      this.authService.bootstrapUser().subscribe((authResponse: UserAuthResponse) => {
        this.authService.storeUserData(authResponse);
        window.location.href = window.location.origin;
      }, () => this.isLoading = false);
    }, () => this.isLoading = false);
  }
}
