// native
import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// service
import { DevicesService } from 'app/core/services/devices.service';
import { DoctorsService } from 'app/core/services/doctors.service';
import { AuthService } from 'app/core/services/auth.service';
import { OperatorsService } from 'app/core/services/operators.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { PatientsService } from 'app/core/services/patients.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { TestFormService } from 'app/core/services/test-form.service';
import { TestsRecurringService } from 'app/core/services/tests-recurring.service';
import { TestsService } from 'app/core/services/tests.service';
import { CustomBundlesService } from 'app/core/services/custom-bundles.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { UtilityService } from 'app/core/services/utility.service';
import { TestDefaultService } from 'app/core/services/test-default.service';
import { ErrorService } from 'app/core/services/error.service';
import { DialogService } from 'app/core/services/dialog.service';
import { TechallSessionService } from 'app/core/services/techall-session.service';

// component
import { TestFormComponent } from '../../tests/test-form/test-form.component';

// models
import { CustomBundleDescription, CustomBundlePostDescription, CustomBundlePostRequest, PendingCustomBundleItem, TestGroup } from '../../models';

// constant
import { SUPRA_T_INTENSITIES, EYE, STRATEGY, GROUP, NONE_CORRECTION_TYPE, CONTROL_TYPES, DEFAULT_OCCLUSION_TIME, GOLDMAN_SIZES, TESTING_POSITIONS, DEFAULT_APD_STIMULATION_TIME } from '../../constants';

@Component({
  selector: 'app-custom-bundle-form',
  templateUrl: './custom-bundle-form.component.html'
})
export class CustomBundleFormComponent extends TestFormComponent {
  @Input() action: string;
  @Input() name: string = null;
  @Input() bundledTests?: CustomBundleDescription[] | PendingCustomBundleItem[] = [];

  currentBundleStep: number = 1;
  newTestPromptShown: boolean = false;
  bundleRequestModel: CustomBundlePostRequest;

  direction: 'back' | 'forward' = null;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public devicesService: DevicesService,
    public doctorsService: DoctorsService,
    public operatorsService: OperatorsService,
    public patientsService: PatientsService,
    public testsService: TestsService,
    public testsRecurringService: TestsRecurringService,
    public toastService: ToastrService,
    public testFormService: TestFormService,
    public router: Router,
    public authService: AuthService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public testBundlesService: TestBundlesService,
    public monitorTestService: MonitorTestService,
    public utilityService: UtilityService,
    public testDefaultService: TestDefaultService,
    public errorService: ErrorService,
    public dialogService: DialogService,
    private customBundlesService: CustomBundlesService,
    private techallService: TechallSessionService
  ) {
    super(formBuilder, devicesService, doctorsService, operatorsService, patientsService, testsService, testsRecurringService,
      toastService, testFormService, router, authService, preferencesService, testBundlesService, translateService,
      monitorTestService, utilityService, testDefaultService, errorService, dialogService);
  }
  ngOnInit() {
    this.isNew = !this.name;
    this.isLoading = true;

    this.translateService.get('test').subscribe(() => this.setTranslations());

    this.getFormData().pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      ([testGroups, controlTypes, stimulusTypes, preferences, correctionTypes]) => {
        this.allTestGroups = (<TestGroup[]>testGroups).filter(group => !group.custom_test_bundle_info);
        this.displayedTestGroups = [...this.allTestGroups];

        this.controlTypes = controlTypes;
        this.stimulusTypes = stimulusTypes;
        this.preferences = preferences;
        this.correctionTypes = correctionTypes;

        this.initForm(this.bundledTests[0]);
      },
      error => this.errorService.handleError());
  }

  public getFormData(): Observable<any[]> {
    return forkJoin([
      this.testsService.getTestGroups(true),
      this.testsService.getControlTypes(),
      this.testsService.getStimulusTypes(),
      this.preferencesService.get(),
      this.testsService.getCorrectionTypes()
    ]);
  }

  initForm(test: CustomBundleDescription | PendingCustomBundleItem, isPending: boolean = false) {
    this.isPending = isPending;
    this.shouldSetDefaults = this.isNew && !this.isPending;

    const name = this.isPending ? (<PendingCustomBundleItem>test).name : this.name;
    const { test_group, with_correction, goldman_size, correction_type, supra_t_intensity, eye, monocular, control_type,
      apd_stimulation_time, skip_eye_tracking_calibration, test_foveal_sensitivity, stimulus_type, skip_calibration, occlusion_time,
      cover_directions, cover_digitally_occluded } = test || {};

    this.form = this.formBuilder.group({
      name: [name, [Validators.required]],
      group: [this.shouldSetDefaults ? this.preferences.test_group?.group?.value : test_group?.group?.value, [Validators.required]],
      strategy: [test_group?.strategy?.value, [Validators.required]],
      protocol: [test_group?.protocol?.value, [Validators.required]],
      with_correction: [with_correction || false],
      correction_type: [correction_type || NONE_CORRECTION_TYPE],
      eye: [eye || EYE.BOTH_CLIENT],
      goldman_size: [this.shouldSetDefaults ? GOLDMAN_SIZES.THREE.value : goldman_size],
      supra_t_intensity: [supra_t_intensity || SUPRA_T_INTENSITIES.STANDARD.value],
      monocular: [monocular || false],
      control_type: [control_type],
      test_foveal_sensitivity: [this.shouldSetDefaults ? false : test_foveal_sensitivity],
      stimulus_type: [stimulus_type],
      apd_stimulation_time: [apd_stimulation_time || DEFAULT_APD_STIMULATION_TIME],
      skip_calibration: [skip_calibration],
      skip_eye_tracking_calibration: [skip_eye_tracking_calibration !== undefined ? skip_eye_tracking_calibration : true],
      monocular_color: [null],
      occlusion_time: [occlusion_time || DEFAULT_OCCLUSION_TIME],
      cover_directions: [this.shouldSetDefaults ? TESTING_POSITIONS.PRIMARY_ONLY.value : cover_directions],
      cover_digitally_occluded: [cover_digitally_occluded !== undefined ? cover_digitally_occluded : true]
    });

    this.setGroupChangeListener();
    this.setStrategyChangeListener();

    this.onTestGroupChange(true);

    this.isDisabled && this.form.disable();
  }

  public setTranslations(): void {
    this.title.bold = this.translateService.instant('customBundle');
  }

  onBundleItemSave(direction: 'back' | 'forward') {
    this.removeHintMessages();
    this.direction = direction;

    if (this.isNew && this.testFormService.shouldShowConfirmModal(this.form, this.controlTypes, this.selectedTestGroup, this.selectedTestStrategy, this.selectedTestProtocol)) {
      this.dialogService.openConfirm({
        action: this.translateService.instant('create'),
        message: this.translateService.instant('voiceConfirmMessage'),
        confirmText: this.translateService.instant('yes'),
        cancelText: this.translateService.instant('no'),
        showClose: false
      }).then(result => {
        if (result.confirmed)
          this.bundleItemSave(direction);
        if (result.canceled)
          this.cancelCreate();
      });
    } else {
      this.bundleItemSave(direction);
    }
  }

  private bundleItemSave(direction: 'back' | 'forward') {
    if (direction === 'back') {
      this.updateBundledTests();
      this.currentBundleStep--;
      this.populateFormForStep();
      return;
    }

    if (direction === 'forward' && this.isLastStep) {
      this.updateBundledTests();
      this.newTestPromptShown = true;
    } else if (direction === 'forward') {
      this.updateBundledTests();
      this.currentBundleStep++;
      this.populateFormForStep();
    }
  }

  private updateBundledTests(): void {
    const { name, group, strategy, protocol, with_correction, correction_type, eye, supra_t_intensity, goldman_size, control_type, apd_stimulation_time, skip_eye_tracking_calibration,
      monocular, test_foveal_sensitivity, stimulus_type, skip_calibration, occlusion_time, cover_directions, cover_digitally_occluded } = this.form.value;

    const item: PendingCustomBundleItem = {
      name,
      test_group: {
        group: { value: group, control_type: this.selectedTestGroup?.control_type },
        strategy: strategy ? { value: strategy, control_type: this.selectedTestStrategy?.control_type } : null,
        protocol: protocol ? { value: protocol, control_type: this.selectedTestProtocol?.control_type } : null
      },
      with_correction,
      correction_type: this.correctionTypes?.map(type => type.id).includes(correction_type) ? correction_type : null,
      eye: [EYE.OD, EYE.OS].includes(eye) ? eye : EYE.BOTH_SERVER,
      supra_t_intensity: this.testFormService.calculateIntensityPayload(this.selectedTestStrategy, this.selectedTestProtocol, null, supra_t_intensity, true),
      goldman_size: this.testFormService.calculateGoldmanSizePayload(this.selectedTestStrategy, goldman_size),
      control_type,
      monocular: (this.selectedTestGroup?.value === GROUP.COLOR_VISION || this.selectedTestStrategy?.value === STRATEGY.ESTERMAN) ? monocular : null,
      test_foveal_sensitivity: this.testFormService.isTestWithFoveal(this.selectedTestStrategy) ? test_foveal_sensitivity : false,
      stimulus_type: this.testFormService.isTestWithStimulusType(this.selectedTestStrategy) ? stimulus_type : null,
      apd_stimulation_time: this.testFormService.isTestWithApdStimulationTime(this.selectedTestStrategy) ? apd_stimulation_time : null,
      skip_calibration: this.testFormService
        .isTestWithSkipCalibration(this.selectedTestGroup, this.selectedTestProtocol, this.selectedControlType) ? !!skip_calibration : false,
      skip_eye_tracking_calibration: this.testFormService
        .isTestWithSkipEyeTrackingCalibration(this.selectedTestGroup, this.selectedTestStrategy) ? !!skip_eye_tracking_calibration : null,
      occlusion_time: this.testFormService.calculateOcclusionTimePayload(this.selectedTestGroup, this.selectedTestStrategy, occlusion_time),
      cover_directions: this.testFormService.isTestWithPositions(this.selectedTestStrategy) ? cover_directions : null,
      cover_digitally_occluded: this.testFormService.isTestWithDefaultDigitalOcclusion(this.selectedTestStrategy) ? cover_digitally_occluded : false
    };

    (<PendingCustomBundleItem[]>this.bundledTests)[this.currentBundleStep - 1] = item;
  }

  onNextTestCreate() {
    this.form.controls['group'].markAsUntouched();
    this.form.controls['strategy'].markAsUntouched();

    this.isPending = false;

    this.form.controls['group'].setValue(this.preferences?.test_group?.group?.value ? this.preferences.test_group.group.value : null);

    this.setStrategyDisplay(true);
    this.setProtocolDisplay(true);

    this.form.controls.with_correction.setValue(false);
    this.form.controls.eye.setValue(EYE.BOTH_CLIENT);
    this.form.controls.supra_t_intensity.setValue(SUPRA_T_INTENSITIES.STANDARD.value);
    this.form.controls.occlusion_time.setValue(DEFAULT_OCCLUSION_TIME);
    this.form.controls.cover_directions.setValue(TESTING_POSITIONS.PRIMARY_ONLY.value);
    this.form.controls.cover_digitally_occluded.setValue(false);

    this.currentBundleStep++;
    this.newTestPromptShown = false;
  }

  onSubmit() {
    this.bundleRequestModel = this.buildRequestModel();

    this.expandPostModelForCSuite();
    this.isLoading = true;
    this.customBundlesService.create(this.bundleRequestModel).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(bundle => {
      this.closeForm();
    }, error => this.errorService.handleTestError(error));
  }

  private buildRequestModel(): CustomBundlePostRequest {
    const test_descriptions: CustomBundlePostDescription[] = [];
    const patient = this.techallService.currentSession?.patient.id;

    this.bundledTests.forEach(test => {
      test_descriptions.push({
        test_group: {
          group: test.test_group?.group?.value,
          strategy: test.test_group?.strategy?.value,
          protocol: test.test_group?.protocol?.value,
        },
        with_correction: test.with_correction,
        correction_type: test.correction_type,
        eye: test.eye,
        supra_t_intensity: test.supra_t_intensity,
        goldman_size: test.goldman_size,
        control_type: this.calculateControlType(test.control_type, test.test_group?.group, test.test_group?.strategy, test.test_group?.protocol, true),
        monocular: test.monocular,
        test_foveal_sensitivity: test.test_foveal_sensitivity,
        stimulus_type: test.stimulus_type,
        apd_stimulation_time: test.apd_stimulation_time,
        skip_calibration: test.skip_calibration,
        occlusion_time: test.occlusion_time,
        skip_eye_tracking_calibration: test.skip_eye_tracking_calibration,
        cover_directions: test.cover_directions,
        cover_digitally_occluded: test.cover_digitally_occluded
      });
    });

    const body: CustomBundlePostRequest = {
      name: (<PendingCustomBundleItem>this.bundledTests[0])?.name,
      test_descriptions,
      patient,
    };

    return body;
  }

  closeForm() {
    return this.router.navigate(['/custom-bundles']);
  }

  onBack() {
    if (this.isBackDisabled())
      return;

    if (this.newTestPromptShown)
      this.newTestPromptShown = false;
    else
      this.onBundleItemSave('back');
  }

  onForward() {
    if (this.isForwardDisabled())
      return;

    this.onBundleItemSave('forward');
  }

  private populateFormForStep() {
    this.test = this.bundledTests[this.currentBundleStep - 1];
    this.initForm(this.test, true);
    this.isVoiceWarningDisplayed = this.controlTypes.find(type => type.id === this.test.control_type)?.name === CONTROL_TYPES.VOICE;
  }

  private expandPostModelForCSuite() {
    const csuiteIndexes = [];

    this.bundleRequestModel.test_descriptions.forEach((description, index) => {
      if (description.test_group?.group === GROUP.CSUITE)
        csuiteIndexes.push(index);
    });

    csuiteIndexes.reverse().forEach(position => {
      const monocularColor = this.form?.value?.monocular_color;
      this.bundleRequestModel.test_descriptions.splice(position, 1,
        ...this.testBundlesService.getCSuiteCustomBundleDescriptions(this.bundleRequestModel.test_descriptions[position], monocularColor));
    });
  }

  private removeHintMessages() {
    this.testFormService.hideGroupVisualHint();
    this.testFormService.hideStrategyVisualHint();
  }

  isBackDisabled() {
    if (!this.isNew)
      return this.isFirstStep;
    return !this.form?.valid || (this.isFirstStep && !this.newTestPromptShown);
  }

  isForwardDisabled() {
    if (!this.isNew)
      return this.isLastStep;
    return !this.form?.valid || this.isLastStep;
  }

  get isLastStep(): boolean {
    return this.currentBundleStep >= this.bundledTests.length;
  }

  get isFirstStep(): boolean {
    return this.currentBundleStep <= 1;
  }
}