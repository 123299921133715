export interface MonitorEvent {
  type: string;
  data: string | DeviceScreen | Battery | PerimetryRecord | AcuityOptotypeRecord | ContrastRecord
  | EomsRecord | ColorTestState | IshiharaState | WaggonerState | PupillometryState | CoverState | SensorimotorState | number;
}

export interface MonitorAction {
  value: string;
  translationKey?: string;
}

export interface PerimetryState {
  stimuli: PerimetryStimulus[];
  progress: TestProgress;
}

export interface PerimetryRecord {
  stimulus: PerimetryStimulus;
  progress: TestProgress;
}

export interface PerimetryStimulus {
  eye: string;
  id: number;
  index: number;
  is_blind_spot: boolean;
  has_good_fixation: boolean;
  response_time: number;
  test: number;
  threshold: boolean;
  viewed: boolean;
  x: number;
  y: number;
  region_no?: 0 | 1 | 2 | 3 | 4 | 5;
  false_negative: boolean;
  false_positive?: boolean;
  seq_num: number;
}

export interface TestProgress {
  od: number;
  os: number;
  total: number;
}

export interface AcuityOptotypeRecord {
  eye: string;
  id: number;
  response_time: number;
  test: number;
  viewed: boolean;
  size: number;
  size_label: string;
  direction: number;
  seq_num: number;
  threshold: boolean;
  contrast?: number;
  version?: number;
}

export interface EomsRecord {
  attempt: number,
  direction: number,
  x: number,
  y: number,
  eye: string,
  monocular: boolean,
  response_time: number,
  test: number,
  viewed: boolean;
  angle: number;
  has_good_fixation: boolean;
  is_blind_spot: boolean;
}

export interface ContrastRecord {
  answer: string;
  contrast: number;
  eye: string;
  guid: string;
  index: number;
  phase: number;
  question: string;
  seq_num: number;
  test: number;
  threshold: boolean;
  variants: string;
  correct?: boolean;
}

export interface PupillometryState {
  test_id: number;
  test_percentage_done: number;
  os_state: PupillometryEyeState;
  od_state: PupillometryEyeState;
  seq_num: number;
}

export interface PupillometryEyeState {
  is_currently_tested: boolean;
  is_retesting: boolean;
  period_type: string;
  period_completion_percentage: number;
}

export interface DeviceScreen {
  id: number;
  name: string;
  active: boolean;
}

export interface Battery {
  battery_level_headset_percentage: number;
  battery_level_handpiece_percentage: number;
}

type ColorScale = [number, string];

export interface PlotData {
  x: number[];
  y: number[];
  visible?: boolean;
  text?: string[];
  mode?: string;
  connectgaps?: boolean;
  type?: string;
  textposition?: string;
  textfont?: {
    family?: string;
    color?: string;
  };
  marker?: {
    cmin?: number;
    cmax?: number;
    symbol?: string;
    opacity?: number;
    colorscale?: ColorScale[];
    color: number[];
  };
  line?: {
    color?: string;
    width?: number;
    shape?: string;
  };
}

export class EyeStats {
  public blindspotCount: number = 0;
  public falsePositiveCount: number = 0;
  public falseNegativeCount: number = 0;
  public totalFalseNegativeTestsCount: number = 0;
  public totalLocationCount: number = 0;
  public totalViewedCount: number = 0;
  public totalProcessedCount: number = 0;
  public completionPercentage: number = 0;
}

export interface ColorTestState {
  eye_states: ColorTestEyeState[];
  seq_num: number;
}

export interface ColorTestEyeState {
  id: number;
  eye: string;
  target_row: ColorTestRecord[];
  guid?: string;
  seq_num?: number;
}

export interface ColorTestRecord {
  index: number;
  color: string;
}

export interface IshiharaState {
  eye_states: IshiharaEyeState[];
  seq_num: number;
}

export interface IshiharaEyeState {
  eye: string;
  responses: IshiharaRecord[];
}

export interface IshiharaRecord {
  answer: string;
  answered: boolean;
  value: string;
}

export interface WaggonerState {
  eye_states: WaggonerEyeState[];
  seq_num: number;
}

export interface WaggonerEyeState {
  eye: string;
  sections: WaggonerSection[];
  current_section: number;
  done: boolean;
  percentage_done: number;
  result: WaggonerEyeResult;
}

export interface WaggonerSection {
  section: number;
  done: boolean;
  correct_answers: number;
  total_plates: number;
  total_answers: number;
  result: string;
  all_data_received: boolean;
}

export interface WaggonerEyeResult {
  tritan_deficiency: null | 'Mild' | 'Moderate' | 'Severe';
  protan_deutan_deficiency_type: null | 'Protan' | 'Deutan' | 'Both';
  protan_deutan_deficiency: null | 'Mild' | 'Moderate' | 'Severe';
}

export interface CoverState {
  progress: number;
  eye: string;
  phase?: 0 | 1;
  seq_num: number;
  current_direction?: number;
  finished_directions?: number[];
}

export interface SensorimotorState {
  algorithm_version: 1 | 2 | 3 | 4;
  test_id: number;
  eye: 'OS' | 'OD';
  last_result: SensorimotorRecord;
  percentage: number;
  locations_done: SensorimotorLocation[];
  seq_num: number;
}

export interface SensorimotorRecord {
  attempt_number: number;
  direction: number;
  distance_degrees: number;
  location: SensorimotorLocation,
  threshold: boolean;
  x_units: number;
  y_units: number;
}

export interface SensorimotorLocation {
  location_index: number;
  x_units: number;
  y_units: number;
}