<app-center-container
  [title]="title"
  [showClose]="true"
  closeTooltip="{{ 'exit' | translate | titlecase }}"
  (close)="closeForm()">

  <div class="slider_container" *ngIf="currentStep === 1">
    <span 
      class="slider_icon"
      [ngClass]="{ 'slider_icon--disabled': isBackDisabled() }"
      matTooltip="{{ 'navigateBundleBack' | translate | titlecase }}"
      (click)="onBack()">
      <i class="fas fa-chevron-left"></i>
    </span>
    <span class="slider_title">{{ 'test' | translate }} #&nbsp;{{ currentBundleStep }}</span>
    <span 
      class="slider_icon"
      [ngClass]="{ 'slider_icon--disabled': isForwardDisabled() }"
      matTooltip="{{ 'navigateBundleForward' | translate | titlecase }}"
      (click)="onForward()">
      <i class="fas fa-chevron-right"></i>
    </span>
  </div>

  <form *ngIf="form" [formGroup]="form">

    <ng-container *ngIf="!newTestPromptShown, else newTestTemplate">
      <div [hidden]="currentStep !== 1">

        <div [hidden]="currentBundleStep !== 1 && isNew">

          <app-info-box 
            *ngIf="isResetItemsMessageDisplayed"
            message="{{ 'resetItemsMessage' | translate }}"
            (onClose)="isResetItemsMessageDisplayed = false"> 
          </app-info-box>

          <app-input-wrapper 
            [control]="form.get('device')" 
            label="{{ 'device' | translate }}">
            <mat-select 
              class="form_control"
              formControlName="device" 
              disableOptionCentering 
              panelClass="dropdown"
              name="device"
              aria-label="{{ 'device' | translate }}">
              <mat-option 
                *ngFor="let device of devices" 
                [value]="device" 
                [disabled]="!device.available">
                <span>
                  {{ device.name }}: {{ !device.available ? ('inUse' | translate) : device.code }}
                </span>
                <span 
                  *ngIf="!!device.battery_level_headset_percentage && device.battery_level_headset_percentage <= 30"
                  matTooltip="{{ 'headsetBatteryTooltip' | translate }}">
                  <i class="fas fa-vr-cardboard"></i>
                </span>
                <span 
                  *ngIf="!!device.battery_level_handpiece_percentage && device.battery_level_handpiece_percentage < 50"
                  matTooltip="{{ 'handpieceBatteryTooltip' | translate }}">
                  <i class="fas fa-gamepad"></i>
                </span>
              </mat-option>
            </mat-select>
          </app-input-wrapper>

          <app-input-wrapper 
            [control]="form.get('doctor')" 
            label="{{ 'doctorsName' | translate }}">
            <mat-select 
              class="form_control"
              formControlName="doctor" 
              disableOptionCentering 
              panelClass="dropdown"
              name="doctor"
              aria-label="{{ 'doctorsName' | translate }}">
              <mat-option *ngFor="let doctor of doctors" [value]="doctor.id">
                {{ doctor.first_name }} {{ doctor.last_name }}
              </mat-option>
            </mat-select>
          </app-input-wrapper>

          <app-input-wrapper 
            *ngIf="!authService.isTechallAdmin"
            [control]="form.get('operator')" 
            label="{{ 'operatorsName' | translate }}">
            <mat-select 
              class="form_control"
              formControlName="operator" 
              disableOptionCentering 
              panelClass="dropdown"
              name="operator"
              aria-label="{{ 'operatorsName' | translate }}">
              <mat-option *ngFor="let operator of operators" [value]="operator.id">
                {{ operator.first_name }} {{ operator.last_name }}
              </mat-option>
            </mat-select>
          </app-input-wrapper>
        </div>

        <app-input-wrapper 
          [control]="form.get('group')" 
          label="{{ 'testGroup' | translate }}">
          <mat-select 
            class="form_control"
            formControlName="group" 
            disableOptionCentering 
            panelClass="dropdown"
            name="group"
            aria-label="{{ 'testGroup' | translate }}">
            <mat-option *ngFor="let testGroup of displayedTestGroups" [value]="testGroup.value">
              <i *ngIf="testGroup.value === 'CSUITE'" class="fa fa-layer-group"></i>
              <span>{{ testGroup.name }}</span>
            </mat-option>
          </mat-select>
        </app-input-wrapper>

        <app-info-box 
          *ngIf="testFormService.isGroupVisualHintDisplayed && !selectedTestGroup"
          message="{{ 'resetGroupSelection' | translate }}"
          (onClose)="testFormService.hideGroupVisualHint()"> 
        </app-info-box>
      
        <app-input-wrapper
          *ngIf="displayedTestStrategies?.length > 1" 
          [control]="form.get('strategy')" 
          label="{{ 'testStrategy' | translate }}">
          <mat-select 
            class="form_control"
            formControlName="strategy" 
            disableOptionCentering 
            panelClass="dropdown"
            name="strategy"
            aria-label="{{ 'testStrategy' | translate }}">
            <mat-option
              *ngFor="let testStrategy of displayedTestStrategies"
              [value]="testStrategy.value"
              [ngClass]="{'hidden--force': testStrategy.value === 'BLUE_PUPILLOMETRY'}">
              {{ testStrategy.name }}
            </mat-option>
          </mat-select>
        </app-input-wrapper>

        <app-info-box 
          *ngIf="testFormService.isStrategyVisualHintDisplayed && !selectedTestStrategy"
          message="{{ 'resetStrategySelection' | translate }}"
          (onClose)="testFormService.hideStrategyVisualHint()"> 
        </app-info-box>

        <app-input-wrapper
          *ngIf="displayedTestProtocols?.length > 1" 
          [control]="form.get('protocol')" 
          label="{{ 'testProtocol' | translate }}">
          <mat-select 
            class="form_control"
            formControlName="protocol" 
            disableOptionCentering 
            panelClass="dropdown"
            name="protocol"
            aria-label="{{ 'testProtocol' | translate }}">
            <mat-option
              *ngFor="let testProtocol of displayedTestProtocols"
              [value]="testProtocol.value"
              [ngClass]="{'hidden--force': testProtocol.value === 'QUADRANT'}">
              {{ testProtocol.name }}
            </mat-option>
          </mat-select>
        </app-input-wrapper>

        <app-input-wrapper
          *ngIf="testFormService.isTestWithEyeSelection(selectedTestGroup, selectedTestStrategy, selectedDevice, selectedMonocular)"
          [control]="form.get('eye')" 
          label="{{ testFormService.isEyeMovableTest(selectedTestGroup) ? ('eyeMovable' | translate) : ('eyes' | translate) }}">

          <div class="radio_group">
            <ng-container *ngIf="!testFormService.isEyeMovableTest(selectedTestGroup)">
              <input 
                id="eyes-both"
                class="radio_input" 
                type="radio" 
                formControlName="eye"
                (change)="onEyeChange()"
                value="both">
              <label class="radio_label" for="eyes-both">
                <div class="radio_button"></div>
                <div class="radio_text">{{ 'both' | translate }}</div>
              </label>
            </ng-container>

            <input 
              id="eyes-od"
              class="radio_input" 
              type="radio" 
              formControlName="eye"
              (change)="onEyeChange()"
              value="OD">
            <label class="radio_label" for="eyes-od">
              <div class="radio_button"></div>
              <div class="radio_text">{{ 'right' | translate | titlecase }}</div>
            </label>

            <input 
              id="eyes-os"
              class="radio_input" 
              type="radio" 
              formControlName="eye"
              (change)="onEyeChange()"
              value="OS">
            <label class="radio_label" for="eyes-os">
              <div class="radio_button"></div>
              <div class="radio_text">{{ 'left' | translate | titlecase }}</div>
            </label>
          </div>
        </app-input-wrapper>

        <app-input-wrapper
          *ngIf="testFormService.isTestWithNonConvergenceMonocular(selectedTestGroup, selectedTestStrategy, selectedEye, selectedDevice)"
          [control]="form.get('monocular')">
          <input
            type="checkbox"
            class="checkbox_input"
            formControlName="monocular"
            name="monocular-checkbox"
            (change)="onMonocularCheckboxChange()"
            id="monocular">
          <label 
            for="monocular"
            class="checkbox_label"
            [ngClass]="{ 'checkbox_label--error': form.get('monocular').errors && form.get('monocular').touched }">
            {{ 'monocular' | translate | titlecase }}
          </label>
        </app-input-wrapper>

        <app-input-wrapper
          *ngIf="testFormService.isMonocularConfrontationCheckboxShown(selectedTestGroup, selectedDevice) && !hasConvergenceTestBeforeCurrentStep(currentBundleStep - 1)"
          [control]="form.get('monocular_confrontation')">
          <input
            type="checkbox"
            class="checkbox_input"
            formControlName="monocular_confrontation"
            name="monocular-confrontation"
            id="monocular-confrontation">
          <label 
            for="monocular-confrontation"
            class="checkbox_label">
            {{ 'monocularConfrontation' | translate | titlecase }}
          </label>
        </app-input-wrapper>

        <app-input-wrapper
          *ngIf="testFormService.isMonocularColorCheckboxShown(selectedTestGroup)"
          [control]="form.get('monocular_color')">
          <input
            type="checkbox"
            class="checkbox_input"
            formControlName="monocular_color"
            name="monocular-color"
            id="monocular-color">
          <label 
            for="monocular-color"
            class="checkbox_label">
            {{ 'monocularColor' | translate | titlecase }}
          </label>
          <br>
        </app-input-wrapper>

        <app-input-wrapper
          *ngIf="testFormService.isTestWithCorrection(selectedTestGroup, selectedTestStrategy, test)"
          [control]="form.get('with_correction')">
          <input
            type="checkbox"
            class="checkbox_input"
            formControlName="with_correction"
            id="with_correction"
            name="with_correction">
          <label 
            for="with_correction"
            class="checkbox_label"
            [ngClass]="{ 'checkbox_label--error': form.get('with_correction').errors && form.get('with_correction').touched }">
            {{ 'withCorrection' | translate }}
          </label>
        </app-input-wrapper>

        <app-input-wrapper
          *ngIf="testFormService.isTestWithCorrectionType(selectedTestGroup, test)"
          [control]="form.get('correction_type')"
          label="{{ 'correctionType' | translate }}">
          <mat-select 
            class="form_control"
            formControlName="correction_type" 
            disableOptionCentering 
            panelClass="dropdown"
            name="correction_type"
            aria-label="{{ 'correctionType' | translate }}">
            <mat-option [value]="'none'">{{ 'none' | translate }}</mat-option>
            <mat-option *ngFor="let type of correctionTypes" [value]="type.id">{{ type.name?.toLowerCase() | translate | titlecase }}</mat-option>
          </mat-select>
        </app-input-wrapper>

        <app-input-wrapper
          *ngIf="testFormService.isTestWithIntensity(selectedTestStrategy, selectedTestProtocol, selectedDevice)"
          [control]="form.get('supra_t_intensity')" 
          label="{{ testFormService.isTestWithSupraIntensity(selectedTestStrategy) ? ('supraIntensity' | translate) : ('estermanIntensity' | translate) }}">
          <mat-select 
            class="form_control"
            name="supra_t_intensity"
            formControlName="supra_t_intensity"
            disableOptionCentering 
            panelClass="dropdown"
            aria-label="{{ testFormService.isTestWithSupraIntensity(selectedTestStrategy) ? ('supraIntensity' | translate) : ('estermanIntensity' | translate) }}">
            <mat-option *ngFor="let intensity of supraTIntensities" [value]="intensity.value">
              {{ intensity.label | translate | titlecase }}
            </mat-option>
          </mat-select>
        </app-input-wrapper>

        <app-input-wrapper
          *ngIf="testFormService.isGoldmanSizeDisplayed(selectedTestStrategy, selectedDevice)"
          [control]="form.get('goldman_size')" 
          label="{{ 'goldmanSize' | translate }}">
          <mat-select 
            class="form_control"
            formControlName="goldman_size" 
            disableOptionCentering 
            panelClass="dropdown"
            name="goldman_size"
            aria-label="{{ 'goldmanSize' | translate }}">
            <mat-option
              *ngFor="let goldmanSize of selectedTestStrategy.goldman_size"
              [value]="goldmanSize">
              {{ testFormService.getGoldmanSizeLabel(goldmanSize) }}
            </mat-option>
          </mat-select>
        </app-input-wrapper>

        <app-input-wrapper
          *ngIf="testFormService.isTestWithStimulusType(selectedTestStrategy)"
          [control]="form.get('stimulus_type')" 
          label="{{ 'stimulusType' | translate }}">
          <mat-select 
            class="form_control"
            name="stimulus_type"
            formControlName="stimulus_type"
            disableOptionCentering 
            panelClass="dropdown"
            aria-label="{{ 'stimulusType' | translate }}">
            <mat-option *ngFor="let stimulusType of stimulusTypes" [value]="stimulusType">
              {{ stimulusType }}
            </mat-option>
          </mat-select>
        </app-input-wrapper>

        <app-input-wrapper
          *ngIf="testFormService.isTestWithApdStimulationTime(selectedTestStrategy)"
          [control]="form.get('apd_stimulation_time')" 
          label="{{ 'apdStimulationTime' | translate }}">
          <mat-select 
            class="form_control"
            name="apd_stimulation_time"
            formControlName="apd_stimulation_time"
            disableOptionCentering 
            panelClass="dropdown"
            aria-label="{{ 'apdStimulationTime' | translate }}">
            <mat-option *ngFor="let stimulationTime of apdStimulationTimes" [value]="stimulationTime">
              {{ stimulationTime }}
            </mat-option>
          </mat-select>
        </app-input-wrapper>

        <ng-container *ngIf="testFormService.isTestWithControlType(selectedTestGroup, selectedTestStrategy, selectedTestProtocol, selectedLanguage)">
          <app-info-box 
            *ngIf="isVoiceWarningDisplayed && form.get('control_type')?.value && (selectedTestGroup?.value !== 'COLOR_VISION')"
            message="{{ 'voiceSelectionMessage' | translate }}"
            (onClose)="isVoiceWarningDisplayed = false"> 
          </app-info-box>
  
          <app-input-wrapper
            [control]="form.get('control_type')" 
            label="{{ 'controlType' | translate }}">
            <mat-select 
              class="form_control"
              name="control_type"
              aria-label="{{ 'controlType' | translate }}"
              formControlName="control_type"
              disableOptionCentering 
              panelClass="dropdown"
              (selectionChange)="onControlTypeChange($event.value)">
              <mat-option *ngFor="let type of controlTypes" [value]="type.id"
                [hidden]="type.name === 'VoiceNumber' && selectedTestStrategy?.value !== 'CONTRAST_STANDARD'">
                {{ type.name?.toLowerCase() | translate | titlecase }}
              </mat-option>
            </mat-select>
          </app-input-wrapper>
        </ng-container>

        <app-input-wrapper
          *ngIf="testFormService.isTestWithOcclusionTime(selectedTestGroup, selectedTestStrategy)" 
          [control]="form.get('occlusion_time')" 
          label="{{ 'occlusionTime' | translate }}">
          <input 
            name="occlusion_time"
            class="form_control" 
            type="number" 
            min="1" 
            max="10" 
            formControlName="occlusion_time">
        </app-input-wrapper>

        <app-input-wrapper
          *ngIf="testFormService.isTestWithPositions(selectedTestStrategy)"
          [control]="form.get('cover_directions')" 
          label="{{ 'testingPositions' | translate }}">
          <mat-select 
            class="form_control"
            formControlName="cover_directions" 
            disableOptionCentering 
            panelClass="dropdown"
            name="cover_directions"
            aria-label="{{ 'testingPositions' | translate }}">
            <mat-option *ngFor="let position of testingPositions" [value]="position.value">
              {{ position.label | translate }}
            </mat-option>
          </mat-select>
        </app-input-wrapper>

        <app-input-wrapper
          *ngIf="testFormService.isTestWithDefaultDigitalOcclusion(selectedTestStrategy)"
          [control]="form.get('cover_digitally_occluded')">
          <input
            type="checkbox"
            class="checkbox_input"
            formControlName="cover_digitally_occluded"
            name="cover_digitally_occluded"
            id="cover_digitally_occluded">
          <label 
            for="cover_digitally_occluded"
            class="checkbox_label"
            [ngClass]="{ 'checkbox_label--error': form.get('cover_digitally_occluded').errors && form.get('cover_digitally_occluded').touched }">
            {{ 'coverDigitallyOccluded' | translate }}
          </label>
        </app-input-wrapper>

        <app-input-wrapper
          *ngIf="testFormService.isTestWithFoveal(selectedTestStrategy, selectedDevice)"
          [control]="form.get('test_foveal_sensitivity')">
          <input
            type="checkbox"
            class="checkbox_input"
            formControlName="test_foveal_sensitivity"
            name="test_foveal_sensitivity"
            id="test_foveal_sensitivity">
          <label 
            for="test_foveal_sensitivity"
            class="checkbox_label"
            [ngClass]="{ 'checkbox_label--error': form.get('test_foveal_sensitivity').errors && form.get('test_foveal_sensitivity').touched }">
            {{ 'testFovealSensitivity' | translate }}
          </label>
        </app-input-wrapper>

        <app-input-wrapper 
          *ngIf="testFormService.isTestWithSkipCalibration(selectedTestGroup, selectedTestProtocol, selectedControlType)"
          [control]="form.get('skip_calibration')">
          <input
            type="checkbox"
            class="checkbox_input"
            formControlName="skip_calibration"
            name="skip_calibration"
            id="skip_calibration">
          <label 
            for="skip_calibration"
            class="checkbox_label"
            [ngClass]="{ 'checkbox_label--error': form.get('skip_calibration').errors && form.get('skip_calibration').touched }">
            {{ 'skipCalibration' | translate | titlecase }}
          </label>
        </app-input-wrapper>

        <app-input-wrapper 
          *ngIf="testFormService.isTestWithSkipEyeTrackingCalibration(selectedTestGroup, selectedTestStrategy)"
          [control]="form.get('skip_eye_tracking_calibration')">
          <input
            type="checkbox"
            class="checkbox_input"
            formControlName="skip_eye_tracking_calibration"
            name="skip_eye_tracking_calibration"
            id="skip_eye_tracking_calibration">
          <label 
            for="skip_eye_tracking_calibration"
            class="checkbox_label"
            [ngClass]="{ 'checkbox_label--error': form.get('skip_eye_tracking_calibration').errors && form.get('skip_eye_tracking_calibration').touched }">
            {{ 'skipEyeTrackingCalibration' | translate | titlecase }}
          </label>
        </app-input-wrapper>

        <div [hidden]="currentBundleStep !== 1 && isNew">
          <ng-container *ngIf="testFormService.isTestWithLanguage(selectedDevice)">
            <app-input-wrapper
              [control]="form.get('language')"
              infoIconText="{{ testFormService.isTestWithControlType(selectedTestGroup, selectedTestStrategy, selectedTestProtocol) ? ('languageFormVoiceInfoMessage' | translate) : null }}"
              label="{{ 'language' | translate }}">
              <mat-select
                class="form_control"
                formControlName="language"
                disableOptionCentering
                panelClass="dropdown"
                (selectionChange)="onLanguageChange($event.value)"
                name="language"
                aria-label="{{ 'language' | translate }}">
                <mat-option *ngFor="let language of displayedLanguages" [value]="language.value">
                  {{ language.name | translate | titlecase }}
                </mat-option>
              </mat-select>
            </app-input-wrapper>

            <app-info-box
              *ngIf="testFormService.isLanguageVisualHintDisplayed"
              message="{{ 'resetLanguageSelection' | translate }}"
              (onClose)="testFormService.hideLanguageVisualHint()">
            </app-info-box>
          </ng-container>

          <app-input-wrapper 
            *ngIf="testFormService.isTestWithDisablePause(selectedDevice, selectedTestStrategy)"
            [control]="form.get('disable_pause')">
            <input
              type="checkbox"
              class="checkbox_input"
              formControlName="disable_pause"
              id="disable_pause"
              name="disable_pause">
            <label 
              for="disable_pause"
              class="checkbox_label"
              [ngClass]="{ 'checkbox_label--error': form.get('disable_pause').errors && form.get('disable_pause').touched }">
              {{ 'disablePause' | translate }}
            </label>
          </app-input-wrapper>
        </div>

        <app-input-wrapper
          *ngIf="!preferences?.always_use_subtitles 
            && testFormService.isTestWithSubtitles(selectedDevice, selectedLanguage, selectedTestGroup, selectedTestStrategy)"
          [control]="form.get('subtitles')">
          <input
            type="checkbox"
            class="checkbox_input"
            formControlName="subtitles"
            name="subtitles"
            id="subtitles">
          <label 
            for="subtitles"
            class="checkbox_label"
            [ngClass]="{ 'checkbox_label--error': form.get('subtitles').errors && form.get('subtitles').touched }">
            {{ 'useSubtitles' | translate | titlecase }}
          </label>
        </app-input-wrapper>

        <app-input-wrapper
          *ngIf="testFormService.isTestWithIncreasedSubtitles(selectedDevice, selectedLanguage, selectedSubtitles, selectedTestGroup, selectedTestStrategy)"
          [control]="form.get('subtitle_increase_text_size')">
          <input
            type="checkbox"
            class="checkbox_input"
            formControlName="subtitle_increase_text_size"
            name="subtitle_increase_text_size"
            id="subtitle_increase_text_size">
          <label 
            for="subtitle_increase_text_size"
            class="checkbox_label"
            [ngClass]="{ 'checkbox_label--error': form.get('subtitle_increase_text_size').errors && form.get('subtitle_increase_text_size').touched }">
            {{ 'useIncreasedSubtitles' | translate | titlecase }}
          </label>
        </app-input-wrapper>

        <app-input-wrapper 
          *ngIf="testFormService.isTestWithTutorial(selectedTestGroup, selectedTestStrategy, selectedTestProtocol)"
          [control]="form.get('skip_tutorial')">
          <input
            type="checkbox"
            class="checkbox_input"
            formControlName="skip_tutorial"
            (change)="onSkipTutorialChange($event)"
            id="skip_tutorial"
            name="skip_tutorial">
          <label 
            for="skip_tutorial"
            class="checkbox_label"
            [ngClass]="{ 'checkbox_label--error': form.get('skip_tutorial').errors && form.get('skip_tutorial').touched }">
            {{ 'skipTutorial' | translate }}
          </label>
        </app-input-wrapper>

        <ng-container *ngIf="isConvergenceSectionVisible()">

          <ng-container *ngIf="testFormService.isBothEyesSelected(form), else singleEyeTemplate">
            <app-input-wrapper [control]="form.get('skip_convergence_testing')">
              <input
                type="checkbox"
                class="checkbox_input"
                formControlName="skip_convergence_testing"
                (change)="onSkipConvergenceChange($event)"
                id="skip_convergence_testing"
                name="skip_convergence_testing">
              <label 
                for="skip_convergence_testing"
                class="checkbox_label"
                [ngClass]="{ 'checkbox_label--error': form.get('skip_convergence_testing').errors && form.get('skip_convergence_testing').touched }">
                {{ 'skipConvergenceTesting' | translate }}
              </label>
            </app-input-wrapper>
    
            <app-input-wrapper
              *ngIf="form.get('skip_convergence_testing').value"
              [control]="form.get('monocular')"
              [isIndent]="true">
              <div class="radio_group radio_group--vertical-mobile">
                <input 
                  id="monocular-on"
                  class="radio_input" 
                  type="radio" 
                  formControlName="monocular" 
                  [value]="true">
                <label class="radio_label" for="monocular-on">
                  <div class="radio_button"></div>
                  <div class="radio_text">{{ 'monocularOn' | translate }}</div>
                </label>
    
                <input 
                  id="monocular-off"
                  class="radio_input" 
                  type="radio" 
                  formControlName="monocular" 
                  [value]="false">
                <label class="radio_label" for="monocular-off">
                  <div class="radio_button"></div>
                  <div class="radio_text">{{ 'monocularOff' | translate }}</div>
                </label>
              </div>
            </app-input-wrapper>
          </ng-container>
  
          <ng-template #singleEyeTemplate>
            <br>
            <app-input-wrapper 
              [control]="form.get('monocular')" 
              label="{{ 'binocularFixationPoints' | translate }}"
              [forceMinimized]="true">
              
              <div class="radio_group radio_group--vertical-mobile">
                <input 
                  id="binocular-on"
                  class="radio_input" 
                  type="radio" 
                  formControlName="monocular" 
                  [value]="false">
                <label class="radio_label" for="binocular-on">
                  <div class="radio_button"></div>
                  <div class="radio_text">{{ 'ON' | translate }}</div>
                </label>
        
                <input 
                  id="binocular-off"
                  class="radio_input" 
                  type="radio" 
                  formControlName="monocular" 
                  selected
                  [value]="true">
                <label class="radio_label" for="binocular-off">
                  <div class="radio_button"></div>
                  <div class="radio_text">{{ 'OFF' | translate }}</div>
                </label>
              </div>
              
            </app-input-wrapper>
          </ng-template>
  
        </ng-container>

        <app-input-wrapper 
          *ngIf="testFormService.isTestWithShortTutorial(selectedTestGroup, selectedTestStrategy, selectedTestProtocol, selectedLanguage)"
          [control]="form.get('use_short_tutorial')">
          <input
            type="checkbox"
            class="checkbox_input"
            formControlName="use_short_tutorial"
            (change)="onShortTutorialChange($event)"
            name="use_short_tutorial"
            id="use_short_tutorial">
          <label 
            for="use_short_tutorial"
            class="checkbox_label"
            [ngClass]="{ 'checkbox_label--error': form.get('use_short_tutorial').errors && form.get('use_short_tutorial').touched }">
            {{ 'useShortTutorial' | translate }}
          </label>
        </app-input-wrapper>

        <button 
          *ngIf="isNew"
          type="button"
          class="button button--primary form_button"
          (click)="onBundleItemSave('forward')"
          [disabled]="!form.valid">
          <span class="regular">{{ 'next' | translate }}</span>
        </button>

      </div>
    </ng-container>

    <ng-template #newTestTemplate>
      <div [hidden]="currentStep !== 1">
        {{ 'wouldCreateNew' | translate }}
        <button 
          class="button button--primary form_button"
          (click)="onNextTestCreate()">
          <span class="regular">{{ 'yes' | translate }}</span>
        </button>
        <button 
          class="button button--primary form_button"
          (click)="onStepOneComplete(true)">
          <span class="regular">{{ 'submitBundle' | translate }}</span>
        </button>
      </div>
    </ng-template>

    <div [hidden]="currentStep !== 2">
      <br />
      {{ 'providePatientContact' | translate }}
      <br />
      <app-input-wrapper 
        [control]="form.get('patient_email')" 
        label="{{ 'email' | translate }}"
        identifier="email">
        <input
          autocorrect="off"
          autocapitalize="none"
          class="form_control" 
          type="email"
          name="email" 
          id="email"
          formControlName="patient_email"
          (input)="onPatientEmailChange($event)">
      </app-input-wrapper>

      <app-input-wrapper 
        [control]="form.get('patient_phone')"
        [hasPlaceholderOffset]="true"
        label="{{ 'phone' | translate }}">
        <ngx-mat-intl-tel-input
          class="form_control"
          [ngClass]="{ 'form_control--disabled': disabled }"
          [preferredCountries]="['us']"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          formControlName="patient_phone">
        </ngx-mat-intl-tel-input>
      </app-input-wrapper>

      <button *ngIf="currentStep === 2" class="button button--primary form_button" (click)="onStepTwoComplete()" [disabled]="!isStepTwoValid()">
        <span class="regular">{{ 'next' | translate }}</span>
      </button>
    </div>

    <div [hidden]="currentStep !== 3">
      <ng-container *ngIf="!isRecurringSelected, else recurringFormTemplate">
        {{ 'isRecurring' | translate }}
        <button id="recurring-bundle-yes-button" class="button button--primary form_button" (click)="onRecurringConfirm()">
          <span class="regular">{{ 'yes' | translate }}</span>
        </button>
        <button class="button button--primary form_button" (click)="onSubmit(form)">
          <span class="regular">{{ 'no' | translate }}</span>
        </button>
      </ng-container>

      <ng-template #recurringFormTemplate>
        <app-input-wrapper 
          [control]="form.get('period_in_days')" 
          label="{{ 'periodInDays' | translate }}">
          <input class="form_control" type="number" min="1" max="999" formControlName="period_in_days" name="period_in_days">
        </app-input-wrapper>

        <app-input-wrapper 
          [control]="form.get('end_date')" 
          label="{{ 'endDate' | translate }}">
          <input 
            class="form_control" 
            [matDatepicker]="picker"
            [datePickerFormat]="preferencesService.defaultDateFormat.serverValue"
            [min]="minEndDate"
            formControlName="end_date"
            name="end_date">
          <mat-datepicker-toggle matSuffix [for]="picker" class="form_datepicker-icon">
          </mat-datepicker-toggle>
        </app-input-wrapper>

        <mat-datepicker #picker></mat-datepicker>

        <button (click)="onSubmit(form, true)" class="button button--primary form_button" [disabled]="!form.valid || !form.dirty">
          <span class="regular">{{ 'done' | translate }}</span>
        </button>
      </ng-template>
    </div>

  </form>

</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>